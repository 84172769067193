<template>
  <div id="article">
    <v-flex xs12>
      <v-layout wrap class="article">
        <v-flex xs11 sm11 md11 offset-sm1 offset-md1 v-if="!isMobile">
          <span class="text"> {{ $t('article.title')}} </span>
        </v-flex>
        <v-flex offset-md1 v-if="data" :class="isMobile ? 'xs12 sm12 md12 mt-9' : 'xs10 sm10 md10 offset-xs1 offset-sm1 mt-9'">
          <v-layout wrap>
            <v-flex xs12 sm6 md4 v-for="(item, index) in data" :key="index">
              <v-card
                class="mx-auto mb-5"
                max-width="90%"
              >
                <v-img
                  height="250"
                  :src="item.icon"
                ></v-img>
            
                <v-card-title class="text-1 pointer" @click="view(item)">{{ item.judul }}</v-card-title>
            
                <v-card-text>      
                  <p class="text-2"> {{ getSomeContent( item.konten) }}</p>
                </v-card-text>
            
                <v-divider class="mx-4"></v-divider>
            
                <v-card-actions>
                  <span class="text-3 pl-2 pointer" @click="view(item)">
                    {{ $t('article.see-more')}}
                  </span>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
         </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Article',
    computed: {
      ...mapState([
        'isMobile'
      ])
    },
    data () {
      return {
        data: null
      }
    },
    methods: {
      view (item) {
        this.$router.push({ name: 'Article-View', params: { slug: item.slug }})
      },
      getSomeContent(item){
        return item.trim().substring(3, 100) + ' ...'
      },
      getArticle () {
        this.$store.commit('setIsLoading', true)
        axios.get('/api/v1/pages/artikel')
          .then(response => {
            this.data = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    },
    mounted () {
      this.getArticle()
      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.$t('article.title'))
    }
  }
</script>

<style scoped>
  .text {
    font-size: 1.7rem;
    color: #000000 !important;
    font-weight: 600;
  }

  .text-1 {
    font-size: 1rem;
    color: #000000 !important;
    font-weight: 600;
    word-break: normal;
    line-height: 20px;
  }

  .text-2 {
    color: #000 !important;
    text-align: justify;
    text-justify: inter-word;
  }

  .text-3 {
    font-size: 0.8rem;
    color: #000 !important;
    text-align: justify;
    text-justify: inter-word;
  }

  .pointer {
    cursor: pointer;
  }

  .article {
    color: #FFFFFF;
    position: relative;
    margin: auto;
    margin-top: 10%;
  }
</style>
